import {Injectable} from "@angular/core";
import {Action, Selector, State, StateContext} from "@ngxs/store";

export class UserLoggedIn {
  static readonly type = '[User] LoggedIn '
}

export class UserLoggedOut {
  static readonly type = '[User] LoggedOut '
}

export class UserUpdated {
  static readonly type = '[User] Updated '
}


export interface UserStateModel {
  loggedIn: boolean;
  updated: boolean;
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    loggedIn: false,
    updated: false
  }
})
export class UserState {

  @Selector()
  static getState(state: UserStateModel): UserStateModel {
    return UserState.getInstanceState(state)
  }

  private static getInstanceState(state: UserStateModel): UserStateModel {
    return {...state};
  }

  private static setInstanceState(state: UserStateModel): UserStateModel {
    return {...state};
  }

  @Action(UserLoggedIn)
  loggedIn(ctx: StateContext<UserStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      loggedIn: true
    });
  }

  @Action(UserLoggedOut)
  loggedOut(ctx: StateContext<UserStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      loggedIn: false
    });

  }

  @Action(UserUpdated)
  updated(ctx: StateContext<UserStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      updated: true
    });

  }
}
